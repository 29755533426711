import React from 'react';
import './logocomponent.css';

function LogoComponent() {
  return (
    <div className="logo-component">
      <div className="logo-text">S&P</div>
      <div className="logo-subtext">media</div>
    </div>
  );
}

export default LogoComponent;
