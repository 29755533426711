import React, { useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import MainContentText from './maincontent';
import './struktur.css';
import './maincontent.css';
import MenuComponent from './menucomponent'; // Import der Menükomponente
import LogoComponent from './logocomponent'; // Import der Logo-Komponente

function Header() {
  return (
    <Row className='header-row' noGutters>
      <Col xs={8} md={8} lg={8} className='p-0'>
        {/* Left part of the header */}
        <div className='header-left'>
          Left Header Content
        </div>
      </Col>
      <Col xs={4} md={4} lg={4} className='p-0'>
        {/* Right part of the header */}
        <div className='header-right'>
          <MenuComponent /> {/* Einbetten der Menükomponente */}
        </div>
      </Col>
    </Row>
  );
}

function MainContent() {
  const mainContentRef = useRef(null);

  return (
    <Row className="main-content-container" noGutters>
    
    <Col xs={8} md={8} lg={8} className='p-0'>
        <div className="main-area" ref={mainContentRef}>
          {/* Main Content Area */}
          {MainContentText.map((section, index) => (
            <div key={index} className={`section ${index % 2 === 0 ? 'even' : 'odd'}`}>
              <h1>{section.title}</h1>
              {section.content.map((item, subIndex) => {
                if (item.type === 'text') {
                  return <p key={subIndex}>{item.value}</p>;
                } else if (item.type === 'image') {
                  return <img key={subIndex} src={item.value} alt={`Content Image ${subIndex}`} />;
                }
                return null;
              })}
            </div>
          ))}
        </div>
      </Col>
      <Col xs={4} md={4} lg={4} className='p-0'>
        <div className="right-sidebar">
          <LogoComponent mainContentRef={mainContentRef} /> {/* Einbetten der Logo-Komponente */}
        </div>
      </Col>
    </Row>
  );
}

function Footer() {
  return (
    <Row className="footer-row" noGutters>
      <Col xs={8} md={8} lg={8} className='p-0'>
        <div className="footer-content">
          Footer Content
        </div>
      </Col>
      <Col xs={4} md={4} lg={4} className='p-0'>
        <div className="footer-right"></div>
      </Col>
    </Row>
  );
}

export { Header, MainContent, Footer };
