import './App.css';
import { Container } from 'react-bootstrap';
import { Header, MainContent, Footer } from './struktur';

function App() {
  return (
    <Container fluid>
      {/* Header */}
      <Header />

      {/* Main Content */}
      <MainContent />

      {/* Footer */}
      <Footer />
    </Container>
  );
}

export default App;
