import test from './img/test.png';

const MainContentText = [
  {
    title: 'Main Content Area 1',
    content: [
      { type: 'text', value: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr...' },
      { type: 'image', value: test },
      { type: 'text', value: 'More text after the image.' }
    ]
  },
  {
    title: 'Main Content Area 2',
    content: [
      { type: 'text', value: 'Another section with only text.' }
    ]
  },
  {
    title: 'Main Content Area 3',
    content: [
      { type: 'text', value: 'Another section with only text.' }
    ]
  },
  {
    title: 'Main Content Area 4',
    content: [
      { type: 'text', value: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr...' },
      { type: 'image', value: test },
      { type: 'text', value: 'More text after the image.' }
    ]
  },
  // Weitere Abschnitte hier hinzufügen
];

export default MainContentText;

